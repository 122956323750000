import React from "react";
import { Container } from "../components/Shared/Container/Container";

import Layout from "../components/Shared/Layout/Layout";

const NotFoundPage = () => (
  <Layout>
    <Container className="relative pt-20">
      <h1 style={{
      }} className="text-center text-5xl md:text-9xl transform scale-150 font-bold text-gray-100">404</h1>
      <h2 className="mt-16 text-gray-600 text-center text-2xl md:text-3xl">Je nám to lúto no daná stránka nebola nájdená</h2>
    </Container>
  </Layout>
);

export default NotFoundPage;
